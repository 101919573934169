import React from 'react';
import lab from '../assets/img/portfolio/Labiconicslogo.png';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = ({ handleNavClick }) => {
    return (
        <div >
            <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
                <div className='container p-5' style={{fontStyle:'Arial'}}>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <h2 className='mb-4'><b>Discover Our Product in Action Request a Demo!</b></h2>
                            <p className='mb-4'>
                                See how our innovative solution can transform your business. Request a demo to experience firsthand how our features can meet your specific needs.
                            </p>
                            <p>
                                Whether you're looking to streamline operations, enhance productivity, or increase ROI, our demo will showcase the benefits tailored for you.
                            </p>
                        </div>
                        <div className='col-md-6 d-flex justify-content-center'>
                            <button className='btn btn-primary btn-lg px-4 py-3' style={{ background: '#47b2e4', borderRadius: '0px' }} onClick={() => handleNavClick('demo')}>
                                <h4 className='mb-0' ><b>REQUEST A DEMO</b></h4>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 footer-info footer-contact">
                                <h4 className='text-lg-start text-sm-center' style={{ fontFamily: "Arial", color: 'rgba(4,52,111)' }}>Lab Iconics Technologies LLP</h4>
                                <p className='text-start' >3rd Floor, TRENDZ PLATINA, 2-91/12/TP,
                                Survey No.12(P), Kondapur Village, 
                                Serilingampally Mandal, Ranga Reddy District,
                                Hyderabad – 500081. Telangana.
                                India.
                                </p>
                                <br></br>
                                <div style={{ color: 'black', textAlign: 'start' }}>
                                    <strong className='text-start' style={{ color: 'rgba(4,52,111)', fontSize: '16px' }}>Phone:</strong><span style={{ fontSize: '15px' }} > +91 8008003103 / +91 8008009926</span><br />
                                    <strong className='text-start' style={{ color: 'rgba(4,52,111)', fontSize: '16px' }}>Email:</strong> <span style={{ fontSize: '16px' }}>info@labiconics.com</span><br />
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4 style={{ color: 'rgba(4,52,111)' }}>Useful Links</h4>
                                <ul className='ml-5 text-dark' style={{ marginLeft: '100px', fontSize: '15px' }}>
                                    <li className='text-dark'><i class="bx bx-chevron-right"></i> <a href="#HOME" onClick={() => handleNavClick('home')}>Home</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#ABOUT" onClick={() => handleNavClick('home')}>About us</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#CAREERS" onClick={() => handleNavClick('careers')}>Careers</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#CONTACT US" onClick={() => handleNavClick('home')}>Contact Us</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#DOWNLOADS" onClick={() => handleNavClick('downloads')}>Downloads</a></li>
                                </ul>
                            </div>

                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4 style={{ color: 'rgba(4,52,111)' }}>Our Services</h4>
                                <ul className='ml-5 myElement' style={{ marginLeft: '100px', fontSize: '15px' }}>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#LIMS" onClick={() => handleNavClick('home')}>Lab Iconics LIMS</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#ELN" onClick={() => handleNavClick('home')}>Lab Iconics ELN</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#ADBS" onClick={() => handleNavClick('home')}>Lab Iconics ADBS</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#QMS" onClick={() => handleNavClick('home')}>Lab Iconics QMS</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#DMS" onClick={() => handleNavClick('home')}>Lab Iconics DMS</a></li>
                                </ul>
                            </div>

                            <div class="col-lg-3 col-md-6 footer-info">
                                <h4 style={{ color: 'rgba(4,52,111)' }}>Our Social Networks</h4>
                                <div class="social-links mt-3">
                                    <a href="https://twitter.com/labiconics" target="_blank" class="twitter" style={{ background: '#47b2e4' }}><i class="bx bxl-twitter"></i></a>
                                    <a href="https://www.facebook.com/people/Lab-Iconics/100090394092719/?mibextid=LQQJ4d" target="_blank" class="facebook" style={{ background: '#47b2e4' }}><i class="bx bxl-facebook"></i></a>
                                    <a href="https://www.linkedin.com/company/lab-icon/" target="_blank" class="linkedin" style={{ background: '#47b2e4' }}><i class="bx bxl-linkedin"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="container">
                    <div className='row'>
                        <div class="copyright col-lg-6">
                            &copy; Copyright 2018 <strong><span>Lab Iconics Technologies LLP</span></strong>. All Rights Reserved
                        </div>
                        <div className='copyright col-lg-6'>
                            Designed by  <a href="https://labiconics.com/" target="_blank" style={{ color: '47b2e4' }}>Lab Iconics Technologies LLP</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
