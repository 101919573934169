import React, { useState } from 'react';
import 'bootstrap';
import Carousel from 'react-bootstrap/Carousel';


import 'aos'
import { Row, Col } from 'react-bootstrap';
import '../assets/css/style.css';
import '../assets/vendor/animate.css/animate.min.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/remixicon/remixicon.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import lab from '../assets/img/portfolio/Labiconicslogo.png'
import rabindranadth from '../assets/img/teams/ravee-removebg-preview.png'
import nbm from '../assets/img/teams/nbm1.png'
import birac from '../assets/img/teams/birac2.jpg'
import dbtlogo from '../assets/img/teams/dbtlogo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faCertificate, faBalanceScale, faUserCheck, faUsers, faSyncAlt, faAward, faTrophy, faMedal, faStar, faRibbon } from '@fortawesome/free-solid-svg-icons';

const About = () => {
    const [showAllAwards, setShowAllAwards] = useState(false);
    const awards = [
        {
            id: 1,
            title: 'India 500 Startup Award - 2019',
            description: 'Received the India 500 Startup Award in 2019 for outstanding achievements.'
        },
        {
            id: 2,
            title: 'MSMECCII Excellence Award - 2020',
            description: 'Recognized with the MSMECCII Excellence Award in 2020 for exemplary contributions.'
        },
        {
            id: 3,
            title: 'Best Innovative Technology Award - 2022',
            description: 'Won the Best Innovative Technology Award from ISAS in 2022 for groundbreaking advancements.'
        },
        {
            id: 4,
            title: 'Grant Award from NBM - BIRAC',
            description: 'Received a Grant Award from the National Biopharma Mission for the "Development of Quality Management System for Biopharmaceutical Industry".'
        }
        // Add more awards as needed
    ];
    const handleExploreAwardsClick = () => {
        setShowAllAwards(true);
    };
    return (<div classNameName='portifoliobody'>
        <main >
            <section id="ABOUT" className="about text-start">
                <div className="container">

                    <div className="section-title text-center" data-aos="zoom-out">
                        <h1 style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }}>ABOUT</h1>
                    </div>

                    <div className="row content" data-aos="fade-up">
                        <div className="col-lg-4 pr-5 mt-5">
                            <h4 style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }}>WHO WE ARE</h4>
                            <p >
                                We are professionals with expertise in Science and Technology, Engineering, Compliance, Laboratory
                                Applications, and services for Healthcare, Pharmaceutical, Biotechnology, Life Sciences, Food, Consumer
                                Goods, and Polymer Industries, as well as Research & Development and Educational Institutions.
                            </p>
                        </div>

                        <div className="col-lg-4 pl-5 pr-5 mt-5">
                            <h4 style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }}>WHAT WE DO</h4>
                            <p>
                                At Lab Iconics, we specialize in providing cutting-edge laboratory informatics solutions. Our commitment is to deliver comprehensive, secure, and compliant solutions customized to the dynamic needs of laboratories. With a focus on data integrity, security, and regulatory compliance, we provide seamless, efficient, and effective operations for our customers' laboratories.
                            </p>
                        </div>

                        <div className="col-lg-4 pl-5 mt-5">
                            <h4 style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }}>OUR VISION AND MISSION</h4>
                            <p><b>Vision:</b>
                                Our vision is to provide technology edge and compliance solutions, enabling our customers to deliver better products right on time.
                            </p>

                            <p><b>Mission:</b>

                                Our Mission is to deliver high-quality lab informatics and compliance solutions and recognize as a preferred "Single Point Solution Provider" in the industry.
                            </p>
                        </div>
                    </div>

                    <div className="text-center mt-5 mb-5" data-aos="zoom-out">
                        <h1 style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }}><b>LEADERSHIP TEAM</b></h1>
                    </div>

                    <div className="row" data-aos="fade-up">
                        <div className="col-lg-6 mt-5">
                            
                            <div className='order-sm-2 order-lg-1'>
                                <p className="" style={{ fontSize: '17px', fontFamily: 'Arial' }}>
                                    Raveendranath Govindaraj, the Founder and Managing Director of Lab Iconics, which was founded in 2018. With a background in Engineering and an MBA in Technology Management, Raveendranath brings over 25 years of extensive experience in Quality systems, Instrumentation, and Laboratory Automation across pharmaceutical, analytical, life science, and lab informatics industries.
                                    Throughout his career, Raveendranath has successfully navigated numerous audits from regulatory agencies including USFDA, MHRA, WHO, and others, showcasing his expertise in ensuring compliance and quality standards. He holds certifications as a DNV Certified Auditor for ISO 14001, and is certified in "Laboratory Management System & Internal Auditing", ISO/IEC 17025:2017, and NABL Requirements.
                                    Raveendranath is a Subject Matter Expert (SME) in Lab Compliance, specializing in 21 CFR Part 11, EU Annex 11, and CSV (Computer System Validation). He has spearheaded the implementation of various IT applications, networking solutions, and data management systems including SDMS, LIMS, ELN, QMS, and DMS.
                                    His visionary leadership and profound knowledge have been instrumental in shaping Lab Iconics as a leading provider of innovative solutions in the laboratory technology space.

                                </p>
                            </div>
                        </div>

                        <div className="col-lg-5 order-sm-1 order-lg-2 d-flex flex-column justify-content-center" >
                            <img src={rabindranadth} alt="Raveendranath Govindaraj" className='ravi' />
                            <div className='text-center pl-5 m-3'>
                                <h3 className='text-left mb-3 pl-5' style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }}>Raveendranath Govindaraj</h3>
                                <h4 className='text-left mb-3 pl-5' style={{ color: 'rgba(4,52,111)' }}>Founder & Managing Director</h4>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section id="portfolio-details" class="portfolio-details">
                <div class="container">
                    <div class="section-title">
                        <h2>Acknowledgement</h2><br></br>
                        <h1 style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }}><b>GRATITUDE TO BIRAC</b></h1>
                    </div>
                    <div className="container">
                        <div className="row content" data-aos="fade-up">
                            <div className="col-lg-4 pr-5 mt-5">
                                <img src={birac} alt="" style={{ width: '22vw', marginBottom: '10%' }} />
                            </div>

                            <div className="col-lg-4 pl-5 pr-5 mt-5">
                                <img src={nbm} alt="" style={{ width: '22vw', marginTop: '5%' }} />
                            </div>

                            <div className="col-lg-4 pl-5 mt-5">
                                <img src={dbtlogo} alt="" style={{ width: '22vw', marginTop: '1%' }} />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="portfolio-description">

                            <p >
                                <li style={{ listStyleType: 'none' }}>We are grateful to National Biopharma Mission, BIRAC, Department of Biotechnology, Govt. of India for their generous grant support, which has played a pivotal role in the development of Lab Iconics LIMS, Lab Iconics ELN and Lab Iconics ADBS. Their funding has empowered us to innovate and deliver cutting-edge solutions, enabling us to revolutionize laboratory management. With BIRAC's support, we continue to advance our mission of enhancing efficiency, compliance, and productivity in laboratories in India and worldwide.</li>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="portfolio-details" class="portfolio-details">
                <div class="container">
                    <div class="section-title">
                    <h1 style={{ fontWeight: 'bold', color: 'rgba(4,52,111)' }}><b>AWARDS</b></h1>
                    </div>
                    <div class="row">
                        <div class="col-sm-7">
                            <Carousel>
                                <Carousel.Item>
                                    <img src={require('../assets/img/portfolio/s1.jpg')} alt="" style={{ width: '100%' }} />

                                </Carousel.Item>
                                 <Carousel.Item>
                                    <img src={require('../assets/img/portfolio/pm1.jpg')} alt="" style={{width:'95%'}}/>
                                </Carousel.Item> 
                                <Carousel.Item>
                                    <img src={require('../assets/img/portfolio/s7.jpg')} alt="" style={{ width: '100%' }} />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={require('../assets/img/portfolio/s5.jpg')} alt="" style={{ width: '100%' }} />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={require('../assets/img/portfolio/s3.jpg')} alt="" style={{ width: '100%' }} />

                                </Carousel.Item>
                            </Carousel>

                        </div>
                        <div class="col-lg-5">
                            <div class="portfolio-description">
                                <h2></h2>
                                <h2></h2>
                                <p>

                                    <li>Grant Award from National Biopharma Mission (NBM) - BIRAC, Department of Biotechnology, Govt. of India for the "Development of Quality Management System for Biopharmaceutical Industry". </li>

                                </p>
                                <p>
                                    <li>Best Innovative Technology Award” from Indian Society of Analytical Scientists (ISAS) - 2022</li>

                                </p>
                                <p>

                                    <li>MSMECCII Excellence Award - 2020</li>

                                </p>
                                <p>

                                    <li>India 500 Startup Award - 2019</li>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="scroll-wrapper">
                            <div id="image-container">
                                <img class="image" src={require('../assets/img/clients/FDA.png')} />
                                <img class="image" src={require('../assets/img/clients/aloca.png')} />
                                <img class="image" src={require('../assets/img/clients/clia.png')} />
                                <img class="image" src={require('../assets/img/clients/eu11.png')} />
                                <img class="image" src={require('../assets/img/clients/gxp.png')} />
                                <img class="image" src={require('../assets/img/clients/hippa.png')} />
                                <img class="image" src={require('../assets/img/clients/iso.png')} />
                                <img class="image" src={require('../assets/img/clients/ispe.png')} />

                            </div>
                        </div>
            </section>
            


            {/* <div style={{ width: '70vw', margin: '0 auto' }}>
                <div className="section-titlee text-center mt-5">
                    <p></p>
                </div>
                <div class="section-title">
                    <h3>Awards</h3>
                </div>
                <div className="services mt-5">
                    <div class="container services mt-5" >
                        <div class="row">

                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300">

                                    <h4 class="title" style={{ color: 'rgba(4,52,111)' }}><a >Project Management</a></h4>
                                    <p class="description">The project management in LAB ICONICS ELN plays a key role in managing the experiments under respective projects by, assigning the projects to the respective groups.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 mt-5">
                                <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="400">

                                    <h4 class="title" style={{ color: 'rgba(4,52,111)' }}><a >Forms & Templates</a></h4>
                                    <p class="description">The features in the LAB ICONICS ELN includes Configurable forms and templates, which helps in saving an ample of time by updating / entering the required details in standard template or forms. </p>
                                </div>
                            </div>

                            <div className='core-values-container text-center ml-5 mr-5'>
                                <Row className="justify-content-center">
                                    {awards.slice(0, showAllAwards ? awards.length : 2).map((award) => (
                                        <Col key={award.id} md={6} className="core-value d-flex flex-column">
                                            <div className='shadow-lg text-center p-5 flex-grow-1 mt-4' style={{ backgroundColor: showAllAwards ? 'info' : '', transition: 'background-color 0.3s ease' }}>
                                                <FontAwesomeIcon icon={faAward} size="4x" className={showAllAwards ? 'text-gold' : 'text-info'} style={{ transition: 'color 0.3s ease' }} />
                                                <h3 className='mt-2'><b>{award.title}</b></h3>
                                                <p className='mt-3'>{award.description}</p>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='core-values-container text-center ml-5 mr-5'>
                    <Row className="justify-content-center">
                        {awards.slice(0, showAllAwards ? awards.length : 2).map((award) => (
                            <Col key={award.id} md={6} className="core-value d-flex flex-column">
                                <div className='shadow-lg text-center p-5 flex-grow-1 mt-4' style={{ backgroundColor: showAllAwards ? 'info' : '', transition: 'background-color 0.3s ease' }}>
                                    <FontAwesomeIcon icon={faAward} size="4x" className={showAllAwards ? 'text-gold' : 'text-info'} style={{ transition: 'color 0.3s ease' }} />
                                    <h3 className='mt-2'><b>{award.title}</b></h3>
                                    <p className='mt-3'>{award.description}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>

                {!showAllAwards && (
                    <div className="text-center mt-5">
                        <button className="explore-button bg-info text-light rounded-3 pl-5 pt-2 pb-2 pr-5 mb-2" onClick={handleExploreAwardsClick}>
                            Explore More Awards
                        </button>
                    </div>
                )}
            </div> */}
        </main>


        <svg className="hero-waves" style={{ background: 'rgb(4,52,111)' }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none">
            <defs>
                <path id="wave-path" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="wave1">
                <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
            </g>
            <g className="wave2">
                <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
            </g>
            <g className="wave3">
                <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
            </g>
        </svg>
    </div>

    );
};

export default About;